<template>
    <div class="content">
        <div class="header">
            <div class="container flex-c">
                <router-link to="/salon/antizhulik"
                    ><img class="header__logo" src="../../assets/img/antizhulik/antizhulik-logo.svg" alt=""
                /></router-link>
                <div class="flex-c header__desktop-buttons  lg-only">
                    <a href="#" class="header__check">Проверить авто</a>
                    <router-link to="/salon/antizhulik/get" class="button button--hollow "
                        >Получить инструкцию</router-link
                    >
                </div>
                <router-link to="/salon/antizhulik/get" class="button button--hollow tac"
                    >Получить инструкцию</router-link
                >
            </div>
        </div>
        <div class="main">
            <div class="antizhulik-lead lg-only">
                <div class="row">
                    <div class="col lg-6 sm-12">
                        <div class="antizhulik-lead__date text_16_m">
                            Проверка авто с 2009 года
                        </div>
                        <div class="text_54_xb antizhulik-lead__title">Инструкция проверки авто перед покупкой</div>
                        <div class="text_24_m antizhulik-lead__subtitle">
                            Поможет отличить убитый авто от хорошего и собственника от перекупа.
                        </div>
                        <router-link to="/salon/antizhulik/get" class="button button--lg"
                            >Получить инструкцию</router-link
                        >
                    </div>
                    <div class="col lg-6 sm-12">
                        <div class="antizhulik-lead__car text_16_m">
                            <div class="antizhulik-lead__label">
                                <div>
                                    Для тех кто хочет купить б/у авто и не попасть в руки мошенников
                                </div>
                            </div>
                            <div class="car-hover" :style="`--x:${hoverX}px;--y:${hoverY}px`">
                                <div class="car-hover__lence car-hover__lence--bg"></div>
                                <div class="car-hover__lence car-hover__lence--stroke"></div>
                                <img
                                    @mouseleave="
                                        hoverX = 300;
                                        hoverY = 250;
                                    "
                                    @mousemove="onMove"
                                    src="../../assets/img/antizhulik/car-new.svg"
                                    alt=""
                                />
                                <img src="../../assets/img/antizhulik/car-broken.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="antizhulik-lead sm-only tac">
                <div class="antizhulik-lead__car text_16_m">
                    <div class="antizhulik-lead__label">
                        <div>
                            Для тех кто хочет купить б/у авто и не попасть в руки мошенников
                        </div>
                    </div>
                    <div class="antizhulik-lead__date text_16_m">
                        Проверка авто с 2009 года
                    </div>
                    <div class="text_54_xb antizhulik-lead__title">Инструкция проверки авто перед покупкой</div>
                    <div class="text_24_m antizhulik-lead__subtitle">
                        Поможет отличить убитый авто от хорошего и собственника от перекупа.
                    </div>
                    <router-link to="/salon/antizhulik/get" class="button button--lg">Получить инструкцию</router-link>
                    <img src="../../assets/img/antizhulik/car-new.svg" alt="" />
                </div>
                <div class="row">
                    <div class="col lg-6 sm-12"></div>
                    <div class="col lg-6 sm-12"></div>
                </div>
            </div>
            <div class="row">
                <div class="col lg-6 md-10 sm-12 col--centered">
                    <div class="text_40_xb tac">В инструкции вы узнаете</div>
                    <ul class="antizhulik-list antizhulik-list--check text_24_m">
                        <li class="antizhulik-list__item">
                            Что делать с фото ПТС, куда в нем смотреть
                        </li>
                        <li class="antizhulik-list__item">
                            Какие 6 сайтов вам нужны для проверки авто и какие данные от туда важны
                        </li>
                        <li class="antizhulik-list__item">Как проверить продавца, не запрашивая его паспорт</li>
                    </ul>
                </div>
                <div class="col lg-8 md-10 sm-12 col--centered">
                    <div class="card get-to-telegram tac">
                        <div class="text_32_xb get-to-telegram__title">
                            Получите инструкцию<br />на эл.почту или в Telegram
                        </div>
                        <div class="get-to-telegram__prices flex-c">
                            <span class="text_24_b get-to-telegram__price-old">1 500 ₽</span>
                            <span class="text_40_xb get-to-telegram__price">299 ₽</span>
                        </div>
                        <router-link to="/salon/antizhulik/get" class="button button--lg"
                            >Получить инструкцию</router-link
                        >
                    </div>
                </div>
            </div>
            <div class="check-socials">
                <div class="text_24_m check-socials__title">Подпишитесь на нас в социальных сетях</div>
                <div class="flex-c check-socials__list">
                    <a href="https://t.me/Avtobezriska_bot" target="_blank" rel="nofollow noindex noopener"
                        ><svg width="64" height="64" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16Z"
                                fill="#0088CC"
                            />
                            <path
                                d="M8.09992 15.7083C12.3949 13.8371 15.2589 12.6034 16.6919 12.0074C20.7834 10.3056 21.6335 10.01 22.1877 10.0001C22.3095 9.99805 22.582 10.0283 22.7586 10.1715C22.9076 10.2924 22.9486 10.4558 22.9683 10.5705C22.9879 10.6851 23.0123 10.9464 22.9929 11.1505C22.7712 13.4801 21.8118 19.1335 21.3237 21.7427C21.1172 22.8468 20.7105 23.217 20.3168 23.2532C19.4613 23.3319 18.8116 22.6878 17.9829 22.1446C16.6862 21.2946 15.9537 20.7654 14.695 19.936C13.2404 18.9774 14.1834 18.4506 15.0124 17.5896C15.2293 17.3643 18.999 13.9355 19.0719 13.6244C19.0811 13.5855 19.0895 13.4405 19.0034 13.3639C18.9172 13.2874 18.7901 13.3136 18.6983 13.3344C18.5683 13.3639 16.4968 14.7331 12.4839 17.4419C11.8959 17.8457 11.3633 18.0424 10.8862 18.0321C10.3601 18.0207 9.34822 17.7346 8.59598 17.4901C7.67333 17.1902 6.94002 17.0316 7.00388 16.5223C7.03714 16.257 7.40248 15.9856 8.09992 15.7083Z"
                                fill="white"
                            />
                        </svg>
                    </a>
                    <a href="https://vk.com/arlan.avto" target="_blank" rel="nofollow noindex noopener"
                        ><svg width="64" height="64" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16Z"
                                fill="#8ee3ff"
                            />
                            <path
                                d="M8 15.68C8 12.0587 8 10.2507 9.12 9.12C10.256 8 12.064 8 15.68 8H16.32C19.9413 8 21.7493 8 22.88 9.12C24 10.256 24 12.064 24 15.68V16.32C24 19.9413 24 21.7493 22.88 22.88C21.744 24 19.936 24 16.32 24H15.68C12.0587 24 10.2507 24 9.12 22.88C8 21.744 8 19.936 8 16.32V15.68Z"
                                fill="#0077FF"
                            />
                            <path
                                d="M16.512 19.5253C12.864 19.5253 10.784 17.0293 10.6987 12.8693H12.5333C12.592 15.92 13.936 17.2107 15.0027 17.4773V12.8693H16.7253V15.4987C17.776 15.3867 18.8853 14.1867 19.2587 12.864H20.976C20.836 13.5487 20.5563 14.1971 20.1543 14.7688C19.7524 15.3405 19.2369 15.8232 18.64 16.1867C19.3061 16.5182 19.8943 16.9872 20.366 17.5627C20.8376 18.1382 21.1818 18.8071 21.376 19.5253H19.4827C19.0773 18.2613 18.064 17.28 16.7253 17.1467V19.5253H16.5173H16.512Z"
                                fill="white"
                            />
                        </svg>
                    </a>
                    <a href="https://www.instagram.com/arlan.avto/" target="_blank" rel="nofollow noindex noopener"
                        ><svg width="64" height="64" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16Z"
                                fill="#F00073"
                            />
                            <path
                                d="M16 9.20001C18.2 9.20001 18.5 9.20001 19.4 9.20001C20.2 9.20001 20.6 9.40001 20.9 9.50001C21.3 9.70001 21.6 9.80001 21.9 10.1C22.2 10.4 22.4 10.7 22.5 11.1C22.6 11.4 22.7 11.8 22.8 12.6C22.8 13.5 22.8 13.7 22.8 16C22.8 18.3 22.8 18.5 22.8 19.4C22.8 20.2 22.6 20.6 22.5 20.9C22.3 21.3 22.2 21.6 21.9 21.9C21.6 22.2 21.3 22.4 20.9 22.5C20.6 22.6 20.2 22.7 19.4 22.8C18.5 22.8 18.3 22.8 16 22.8C13.7 22.8 13.5 22.8 12.6 22.8C11.8 22.8 11.4 22.6 11.1 22.5C10.7 22.3 10.4 22.2 10.1 21.9C9.8 21.6 9.6 21.3 9.5 20.9C9.4 20.6 9.3 20.2 9.2 19.4C9.2 18.5 9.2 18.3 9.2 16C9.2 13.7 9.2 13.5 9.2 12.6C9.2 11.8 9.4 11.4 9.5 11.1C9.7 10.7 9.8 10.4 10.1 10.1C10.4 9.80001 10.7 9.60001 11.1 9.50001C11.4 9.40001 11.8 9.30001 12.6 9.20001C13.5 9.20001 13.8 9.20001 16 9.20001ZM16 7.70001C13.7 7.70001 13.5 7.70001 12.6 7.70001C11.7 7.70001 11.1 7.90001 10.6 8.10001C10.1 8.30001 9.6 8.60001 9.1 9.10001C8.6 9.60001 8.4 10 8.1 10.6C7.9 11.1 7.8 11.7 7.7 12.6C7.7 13.5 7.7 13.8 7.7 16C7.7 18.3 7.7 18.5 7.7 19.4C7.7 20.3 7.9 20.9 8.1 21.4C8.3 21.9 8.6 22.4 9.1 22.9C9.6 23.4 10 23.6 10.6 23.9C11.1 24.1 11.7 24.2 12.6 24.3C13.5 24.3 13.8 24.3 16 24.3C18.2 24.3 18.5 24.3 19.4 24.3C20.3 24.3 20.9 24.1 21.4 23.9C21.9 23.7 22.4 23.4 22.9 22.9C23.4 22.4 23.6 22 23.9 21.4C24.1 20.9 24.2 20.3 24.3 19.4C24.3 18.5 24.3 18.2 24.3 16C24.3 13.8 24.3 13.5 24.3 12.6C24.3 11.7 24.1 11.1 23.9 10.6C23.7 10.1 23.4 9.60001 22.9 9.10001C22.4 8.60001 22 8.40001 21.4 8.10001C20.9 7.90001 20.3 7.80001 19.4 7.70001C18.5 7.70001 18.3 7.70001 16 7.70001Z"
                                fill="white"
                            />
                            <path
                                d="M16 11.7C13.6 11.7 11.7 13.6 11.7 16C11.7 18.4 13.6 20.3 16 20.3C18.4 20.3 20.3 18.4 20.3 16C20.3 13.6 18.4 11.7 16 11.7ZM16 18.8C14.5 18.8 13.2 17.6 13.2 16C13.2 14.5 14.4 13.2 16 13.2C17.5 13.2 18.8 14.4 18.8 16C18.8 17.5 17.5 18.8 16 18.8Z"
                                fill="white"
                            />
                            <path
                                d="M20.4 12.6C20.9523 12.6 21.4 12.1523 21.4 11.6C21.4 11.0477 20.9523 10.6 20.4 10.6C19.8477 10.6 19.4 11.0477 19.4 11.6C19.4 12.1523 19.8477 12.6 20.4 12.6Z"
                                fill="white"
                            />
                        </svg>
                    </a>
                </div>
            </div>
            <div class="card check-instruction">
                <img src="../../assets/img/antizhulik/warning.svg" alt="" />
                <div class="row row--fullwidth">
                    <div class="col lg-6 md-12">
                        <div class="text_32_xb check-instruction__title">
                            Инструкция по проверки автомобиля - это важная часть перед преобретением авто
                        </div>
                        <img class="lg-only" src="../../assets/img/antizhulik/zhulik.svg" alt="" />
                    </div>
                    <div class="col lg-6 md-12">
                        <div class="text_24_m">
                            <div>Вы узнаете</div>
                            <ul class="antizhulik-list antizhulik-list--cross">
                                <li class="antizhulik-list__item">Как мошенники продают вам автомобили под арестом?</li>
                                <li class="antizhulik-list__item">
                                    Как вы можете лишиться автомобиля через час после его покупки?
                                </li>
                                <li class="antizhulik-list__item">Как не купить авто с заложенным ПТС?</li>
                                <li class="antizhulik-list__item">
                                    Почему нельзя покупать авто через "мужа", "брата", "сестры"?
                                </li>
                            </ul>
                            <div class="check-instruction__footer">
                                Инструкция поможет вам не потерять деньги и не попасть в руки мошенников
                                <router-link to="/salon/antizhulik/get" class="button button--lg"
                                    >Получить инструкцию</router-link
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col lg-10 sm-12 col--centered">
                    <div class="expert">
                        <div class="row">
                            <div class="col lg-6 sm-12">
                                <div class="expert__name text_32_xb tac">Антон</div>
                                <div class="expert__post text_16_m tac">Эксперт сервиса «Антижулик»</div>
                                <div class="expert__quote text_24_m tac">
                                    Данная инструкция создана, чтобы помочь в подборе автомобилей, и снизить кол-во
                                    обманутых людей мошенниками
                                </div>
                                <ul class="antizhulik-list antizhulik-list--check-invert text_18_m">
                                    <li class="antizhulik-list__item">Опыт в автомобильной сфере более 10 лет</li>
                                    <li class="antizhulik-list__item">
                                        Помогаю в подборе поддержанных автомобилей, людям по всей территории РФ
                                    </li>
                                    <li class="antizhulik-list__item">Личный товарный оборот 50 млн. руб. в месяц</li>
                                    <li class="antizhulik-list__item">
                                        В 2013 году начал свой бизнес по продаже поддержанных автомобилей
                                    </li>
                                </ul>
                            </div>
                            <div class="col lg-6 sm-12">
                                <div class="expert__img">
                                    <img
                                        src="../../assets/img/antizhulik/expert-1.jpg"
                                        srcset="../../assets/img/antizhulik/expert-1@2x.jpg 2x"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="expert expert--reverse">
                        <div class="row">
                            <div class="col lg-6 sm-12">
                                <div class="expert__img">
                                    <img
                                        src="../../assets/img/antizhulik/expert-2.jpg"
                                        srcset="../../assets/img/antizhulik/expert-2@2x.jpg 2x"
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div class="col lg-6 sm-12">
                                <div class="expert__name text_32_xb tac">Светлана</div>
                                <div class="expert__post text_16_m tac">Эксперт сервиса «Антижулик»</div>
                                <div class="expert__quote tac text_24_m">
                                    Наша команда собрала гайд, чтобы у вас была пошаговая инструкция ,что спрашивать
                                    продавца, как должен звучать ответ, и с кем сразу стоит заканчивать диалог.
                                </div>
                                <ul class="antizhulik-list antizhulik-list--check-invert text_18_m">
                                    <li class="antizhulik-list__item">Опыт в автомобильной сфере более 14 лет</li>
                                    <li class="antizhulik-list__item">
                                        Карьера от специалиста-оформителя до ген. директора автомобильной компании с
                                        оборотом 240 млн.руб/год.
                                    </li>
                                    <li class="antizhulik-list__item">
                                        Потеряла более 3 млн.руб. на не удачных сделках и на данный момент, я знаю как
                                        безопасно купить поддерженный автомобиль
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col lg-8 md-10 sm-12 col--centered">
                    <div class="card get-to-telegram tac">
                        <div class="text_32_xb get-to-telegram__title">
                            Получите инструкцию<br />на эл.почту или в Telegram
                        </div>
                        <div class="get-to-telegram__prices flex-c">
                            <span class="text_24_b get-to-telegram__price-old">1 500 ₽</span>
                            <span class="text_40_xb get-to-telegram__price">299 ₽</span>
                        </div>
                        <router-link to="/salon/antizhulik/get" class="button button--lg"
                            >Получить инструкцию</router-link
                        >
                    </div>
                </div>
            </div>
            <div class="check-block">
                <div class="card card--dark">
                    <div class="row">
                        <div class="col lg-6 md-12">
                            <div class="text_32_xb check-block__title">
                                Нет времени проверять автомобиль? Проверим историю авто за вас
                            </div>
                        </div>
                        <div class="col lg-6 md-12">
                            <ul class="antizhulik-list antizhulik-list--check-blue text_24_m">
                                <li class="antizhulik-list__item">Судебные запреты</li>
                                <li class="antizhulik-list__item">ДТП</li>
                                <li class="antizhulik-list__item">Оригинальность ПТС</li>
                                <li class="antizhulik-list__item">Проверка по 6 базам данных</li>
                            </ul>
                            <div class="check-block__footer">
                                <div class="text_24_m">
                                    Проверку автомобиля осуществляют эксперты компании «Антижулик» с опытом более 10 лет
                                </div>
                                <a href="#" class="button button--lg">Проверить автомобиль</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer text_16_m">
            © Анти Жулик, 2009 — {{ year }} <a href="#">Пользовательское соглашение</a>
            <ZhulikCallBack></ZhulikCallBack>
        </div>
    </div>
</template>
<script>
// import axios from "axios";
import ZhulikCallBack from "@/components/ZhulikCallBack";
export default {
    name: "Atizhulik",
    components: { ZhulikCallBack },
    data() {
        return {
            hoverX: 300,
            hoverY: 250,
            year: new Date().getFullYear(),
        };
    },
    methods: {
        onMove(e) {
            this.hoverX = e.offsetX;
            this.hoverY = e.offsetY;
        },
    },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;700;800&display=swap");
</style>
<style lang="less">
@import url("../../assets/less/vars.less");
.lg-only {
    @media @sm {
        display: none !important;
    }
}
.sm-only {
    @media @notsm {
        display: none !important;
    }
}
#app.page-antizhulik {
    background: none;
    overflow-x: hidden;
    > .header,
    > .footer {
        display: none;
    }
    .text_24_b {
        font-weight: 700;
        font-size: 18px;
    }
    .main {
        padding-top: 130px;
        @media @sm {
            padding-top: 0;
        }
    }
    .button {
        text-decoration: none;
        background: @mainPurple;
        height: 42px;
        padding: 0 12px;
        box-shadow: none;
        font-weight: 500;
        font-size: 16px;
        @media @sm {
            font-size: 13px;
            width: auto;
        }
        &--lg {
            padding: 0 26px;
            height: 65px;
            font-size: 18px;
            @media @sm {
                height: 46px;
                font-size: 16px;
            }
        }
        &--hollow {
            background: none;
            color: @mainPurple;
            border: 1px solid;
        }
    }
    .card {
        background: #f6f4ff;
        border-radius: 16px;
    }
    .container {
        max-width: 1540px;
        padding-left: 130px;
        padding-right: 130px;
        @media (max-width: 1180px) {
            padding-left: 60px;
            padding-right: 60px;
        }
        @media @sm {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    .content {
        font-family: "Inter", sans-serif;
        padding-bottom: 0;
        @media @sm {
            margin-top: 64px;
        }
        .header {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            padding: 12px 0;
            background: #fff;
            z-index: 1;
            box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.05);
            @media @sm {
                padding: 8px 0;
            }
            .container {
                justify-content: space-between;
                padding-left: 20px;
                padding-right: 20px;
                gap: 35px;
                > .button {
                    display: none;
                    @media @sm {
                        display: inline-flex;
                        text-align: center;
                        height: 48px !important;
                    }
                }
            }
            &__check {
                margin-right: 40px;
                color: @mainPurple;
            }
            &__desktop-buttons {
                @media @sm {
                    display: none;
                }
            }
            &__logo {
                @media @sm {
                    height: 40px;
                }
            }
        }
        .footer {
            margin-top: 64px;
            padding: 24px 0;
            color: #999999;
            text-align: left;
            background: none;
            border-top: 1px #e6e6e6 solid;
            display: flex;
            align-items: center;
            @media @sm {
                margin-top: 24px;
                padding: 16px 0;
                text-align: center;
            }
            a {
                color: #999999;
                &:hover {
                    color: @mainPurple;
                }
                @media @sm {
                    display: block;
                }
            }
        }
    }

    .antizhulik-lead {
        margin-bottom: 160px;
        @media @sm {
            margin-bottom: 80px;
        }
        &__date {
            background: #f6f4ff;
            margin-bottom: 24px;
            display: inline-block;
            padding: 6px 14px;
            border-radius: 4px;
            color: #626266;
            @media @sm {
                margin-bottom: 16px;
            }
        }
        &__title {
            margin-bottom: 24px;
        }
        &__subtitle {
            margin-bottom: 48px;
        }
        &__car {
            position: relative;
            border-radius: 16px;
            background: url(../../assets/img/antizhulik/lead-bg.svg) center / cover, no-repeat;
            padding-bottom: 60%;
            @media @sm {
                border-radius: 0;
                background: linear-gradient(180deg, #efe6ff 31.89%, #def8ff 120.12%);
                margin-left: -20px;
                margin-right: -20px;
                padding-left: 20px;
                padding-right: 20px;
                padding-bottom: 0;
                img {
                    max-width: 100%;
                    display: block;
                    margin-top: 40px;
                }
            }
        }
        &__label {
            padding: 25px 0;
            overflow: hidden;
            @media @sm {
                margin-left: -20px;
                margin-right: -20px;
                padding-bottom: 30px;
            }
            div {
                text-align: center;
                color: #fff;
                padding: 16px;
                background: @mainGreen;
                transform: rotate(-2deg);
                @media @sm {
                    margin-left: -2px;
                    margin-right: -2px;
                }
            }
        }
    }
    .car-hover {
        position: absolute;
        bottom: 0;
        &__lence {
            position: absolute;
            left: var(--x);
            top: var(--y);
            width: 268px;
            height: 268px;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            pointer-events: none;
            margin-left: -79px;
            &--bg {
                background: #e7efff;
            }
            &--stroke {
                box-shadow: 0 0 0 4px #fff;
            }
        }
        img {
            margin-left: -79px;
            display: block;
            + img {
                position: absolute;
                bottom: 0;
                left: 0;
                pointer-events: none;
                clip-path: circle(134px at var(--x) var(--y));
            }
        }
    }
    .antizhulik-list {
        margin-top: 40px;
        margin-bottom: 64px;
        margin-left: 0;
        padding-left: 0;
        list-style-type: none;
        @media @sm {
            margin-top: 24px;
            margin-bottom: 40px;
        }
        &--check .antizhulik-list {
            &__item::before {
                background: url(../../assets/img/antizhulik/check.svg) center / contain no-repeat;
            }
        }
        &--check-blue .antizhulik-list {
            &__item::before {
                background: url(../../assets/img/antizhulik/check-blue.svg) center / contain no-repeat;
            }
        }
        &--check-invert .antizhulik-list {
            &__item {
                padding-left: 32px;
                &::before {
                    width: 16px;
                    height: 16px;
                    background: url(../../assets/img/antizhulik/check-invert.svg) center / contain no-repeat;
                }
            }
        }
        &--cross .antizhulik-list {
            &__item::before {
                background: url(../../assets/img/antizhulik/cross.svg) center / contain no-repeat;
            }
        }
        &__item {
            padding-left: 45px;
            position: relative;
            &::before {
                content: "";
                width: 32px;
                height: 32px;
                position: absolute;
                left: 0;
                top: 0;
            }
            + .antizhulik-list__item {
                margin-top: 28px;
            }
        }
    }
    .get-to-telegram {
        padding: 64px;
        margin-bottom: 80px;
        @media @sm {
            padding: 56px 20px;
            margin-bottom: 40px;
        }
        &__title {
            margin-bottom: 24px;
            @media @sm {
                margin-bottom: 16px;
            }
        }
        &__prices {
            justify-content: center;
            gap: 16px;
            margin-bottom: 24px;
            @media @sm {
                margin-bottom: 16px;
            }
        }
        &__price {
            color: #e84474;
        }
        &__price-old {
            position: relative;
            &::before {
                content: "";
                position: absolute;
                box-shadow: 0px 1px 0px #f9f5ff;
                transform: rotate(-9deg);
                background: #000;
                height: 2px;
                left: 0;
                right: 0;
                top: 50%;
                margin-top: -1px;
            }
        }
    }
    .check-socials {
        margin-bottom: 80px;
        a {
            svg {
                display: block;
                border-radius: 50%;
                width: 64px;
                height: 64px;
                .trs(box-shadow);
                &:hover {
                    box-shadow: 0 0 15px @mainPurple;
                }
                @media @sm {
                    width: 32px;
                    height: 32px;
                }
            }
        }
        @media @sm {
            margin-bottom: 40px;
        }
        &__list {
            justify-content: center;
            gap: 16px;
        }
        &__title {
            margin-bottom: 32px;
            text-align: center;
        }
    }
    .check-instruction {
        padding-top: 60px;
        margin-bottom: 160px;
        overflow: hidden;
        @media @md {
            img {
                display: none;
            }
        }

        @media @sm {
            padding-top: 0;
            margin-bottom: 80px;
            text-align: center;
        }
        .row {
            padding: 0 110px;
            margin-top: 80px;
            @media @sm {
                margin-top: 24px;
                padding: 0 20px;
            }
            .col {
                display: flex;
                flex-direction: column;
                img {
                    margin-top: auto;
                }
            }
        }
        .antizhulik-list {
            margin-top: 24px;
            @media @sm {
                text-align: left;
            }
        }
        &__title {
            @media @md {
                margin-bottom: 40px;
            }
        }
        &__footer {
            padding-bottom: 160px;
            margin-top: 64px;
            @media @sm {
                margin-top: 40px;
                padding-bottom: 56px;
            }
            .button {
                margin-top: 40px;
            }
        }
    }
    .expert {
        margin-bottom: 160px;
        @media @sm {
            margin-bottom: 80px;
            &:not(.expert--reverse) {
                .row {
                    flex-direction: column-reverse;
                }
            }
        }
        &__name {
            margin-bottom: 4px;
            @media @sm {
                margin-top: 20px;
            }
        }
        &__post {
            opacity: 0.4;
            margin-bottom: 24px;
        }
        &__quote {
            color: @mainPurple;
            margin-bottom: 64px;
            @media @sm {
                margin-bottom: 48px;
            }
            &::before {
                content: url(../../assets/img/antizhulik/quote.svg);
                display: block;
                margin-bottom: 8px;
            }
        }
        &--reverse .expert {
            &__img {
                padding-left: 0;
                padding-right: 40px;
                &::before {
                    left: 0;
                    right: 40px;
                }
            }
        }
        &__img {
            padding-left: 40px;
            position: relative;
            @media @sm {
                margin-left: 20px;
                margin-right: 20px;
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
            &::before {
                content: "";
                position: absolute;
                background: @mainGreen;
                top: 0;
                bottom: 0;
                left: 40px;
                right: 0;
                border-radius: 16px;
                transform: rotate(2deg);
                @media @sm {
                    left: 0 !important;
                    right: 0 !important;
                }
            }
            img {
                max-width: 100%;
                transform: rotate(-2deg);
                border-radius: 16px;
            }
        }
        .row {
            margin-left: 0;
            margin-right: 0;
            .col {
                padding-left: 0;
                padding-right: 0;
            }
        }
        .antizhulik-list {
            @media @sm {
                margin-bottom: 0;
                .antizhulik-list__item + .antizhulik-list__item {
                    margin-top: 16px;
                }
            }
        }
    }
    .check-block {
        color: #fff;
        @media @sm {
            .row {
                margin-left: 0;
                margin-right: 0;
                .col {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
        &__title {
            color: @mainGreen;
            @media @md {
                margin-bottom: 40px;
                text-align: center;
            }
        }
        &__footer {
            margin-top: 64px;
            @media @sm {
                margin-top: 40px;
                text-align: center;
            }
        }
        .button {
            margin-top: 40px;
        }
        .card {
            padding: 80px 110px;
            background: url(../../assets/img/antizhulik/check-block-bg.svg) center / cover no-repeat, #202020;
            @media @sm {
                padding: 56px 20px;
                background: #202020;
            }
        }
        .antizhulik-list {
            margin-top: 0;
        }
    }
}
</style>
