<template>
    <div class="zhulik-call-back">
        <div class="button" @click="showModal = true">Заказать звонок</div>
        <transition name="fade">
            <div v-if="showModal" class="modal zhulik-call-back__modal">
                <div class="zhulik-call-back__dimmer" @click="showModal = false"></div>
                <div class="zhulik-call-back__form">
                    <div class="text_32_b tac">Обратная связь</div>
                    <div class="zhulik-call-back__close" @click="showModal = false">×</div>
                    <div v-if="sended" class="text_24_b tac">
                        Заявка успешно отправлена, менеджер свяжется с вами в ближайшее время
                    </div>
                    <form @submit.prevent="submit()" class="user-form user-form--column" v-else>
                        <div class="user-form__content">
                            <div class="form-group" v-for="(control, name) in form" :key="name">
                                <div class="form-field form-field--wide">
                                    <div class="form-field__label">
                                        {{ control.label }}
                                        <span class="error" v-if="errorMessages[name]">{{
                                            errorMessages[name][0]
                                        }}</span>
                                    </div>
                                    <div class="form-field__component">
                                        <input
                                            class="form-group__input"
                                            v-if="control.type === 'text'"
                                            type="text"
                                            required
                                            v-model="form[name].value"
                                        />
                                        <input
                                            class="form-group__input"
                                            v-if="control.type === 'number'"
                                            type="number"
                                            required
                                            v-model="form[name].value"
                                        />
                                        <vue-phone-number-input
                                            v-if="control.type === 'tel'"
                                            no-flags
                                            :preferred-countries="['RU']"
                                            :translations="phoneTranslations"
                                            v-model="form[name].value"
                                        />
                                        <template v-if="name === 'agree'">
                                            <label class="flex-c form-field__checkbox">
                                                <input type="checkbox" name="agree" v-model="form[name].value" />
                                                <span>
                                                    Я ознакомилась(ся) с политикой конфиденциальности и даю свое
                                                    согласие на
                                                    <a
                                                        href="https://arlan-auto.ru/informaciya?tab=4&subTab=4"
                                                        target="_blank"
                                                        >обработку персональных данных</a
                                                    >
                                                </span>
                                            </label>
                                            <div class="error" v-if="errorMessages.agree">
                                                {{ errorMessages.agree[0] }}
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                            <div class="flex">
                                <button class="button" type="submit" :disabled="!form.agree">
                                    Отправить
                                    <div class="loader" v-if="requestStatus === 'loading'"></div>
                                </button>
                            </div>
                            <div class="text_20 tac submit-error" v-if="requestStatus === 'error'">
                                Произошла ошибка отправки формы, <br />позвоните по номеру телефона
                                <a href="tel:88482613813">8 (8482) 613 - 813</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import axios from "axios";
export default {
    name: "ZhulikCallBack",
    data() {
        return {
            sended: false,
            showModal: false,
            errorMessages: {},
            form: {
                name: {
                    label: "Ваше имя",
                    type: "text",
                    value: "",
                },
                phone: {
                    label: "Ваш телефон",
                    type: "tel",
                    value: "",
                },
                theme: {
                    label: "Ваш вопрос",
                    type: "text",
                    value: "",
                },
                agree: {
                    type: "checkbox",
                    value: 0,
                },
            },
            phoneTranslations: {
                countrySelectorLabel: "Код",
                countrySelectorError: "Ошибка",
                phoneNumberLabel: "Номер телефона",
                example: "Например:",
            },
        };
    },
    methods: {
        async submit() {
            this.requestStatus = "loading";
            axios.defaults.withCredentials = true;
            let postData = {};
            for (const key in this.form) {
                if (Object.hasOwnProperty.call(this.form, key)) {
                    const element = this.form[key];
                    postData[key] = element.type === "tel" ? "+7" + element.value : element.value;
                }
            }
            axios
                .get("https://api.arlan-auto.ru/api/v1/csrf_token")
                .then(() => {
                    axios
                        .post("https://api.arlan-auto.ru/api/v1/amo/create/lead/salon/car-commission", postData)
                        .then(() => {
                            this.sended = true;
                            this.requestStatus = "";
                        })
                        .catch((e) => {
                            console.log(`e =>`, e);
                            this.requestStatus = "error";
                            if (e.response.status === 422) {
                                this.errorMessages = Object.assign(e.response.data.data);
                            }
                        });
                })
                .catch((e) => {
                    console.log(`e =>`, e);
                    this.requestStatus = "error";
                });
        },
    },
};
</script>
<style lang="less">
@import url("../assets/less/vars.less");
.zhulik-call-back {
    margin-left: auto;
    @media @sm {
        margin: 0 auto;
        margin-top: 32px;
    }
    &__modal {
        display: grid;
        place-items: center;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
    }
    &__dimmer {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #0008;
    }
    &__form {
        padding: 32px;
        width: 500px;
        max-width: 90%;
        border-radius: 16px;
        position: relative;
        z-index: 1;
        background: #fff;
    }
    &__close {
        position: absolute;
        top: 0;
        right: 0;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        cursor: pointer;
        color: @mainPurple;
        &:hover {
            color: @red;
        }
    }
    .user-form {
        padding: 0;
        margin-top: 0;
        a {
            margin-left: 0;
        }
    }
}
</style>
